import Vue from "vue";
import store from "../src/store/index";
import axios from "axios";

import conn from "./servicos/http"

export const userKey = "_jaguar";

/*
export const baseApiUrl = store.state.contexto.api.baseApiUrl;
export const baseApiUrlUpload = store.state.contexto.api.baseApiUrlUpload;
export const baseApiRel = store.state.contexto.api.baseApiRel;
export const baseApiDocs = store.state.contexto.api.baseApiDocs;
export const baseApiRelXLS = store.state.contexto.api.baseApiRelXLS;
export const baseApiRelPDF = store.state.contexto.api.baseApiRelPDF;
*/
/* 
  Local


export const baseApiUrl = "http://192.168.5.171:9001";
export const baseApiUrlUpload = "http://192.168.5.171:9000";
export const baseApiRel = `http://jaguar.solutio.net.br/jaguar/wjaprepararel20.jsp?servidor=jaguar.solutio.net.br&banco=bco_nome&relatorio=`;
export const baseApiDocs = `http://jaguar.solutio.net.br/jaguar/`;
export const baseApiRelXLS = `http://jaguar.solutio.net.br/jaguar/wjaprepararelxls20.jsp?servidor=jaguar.solutio.net.br&banco=bco_nome&relatorio=`;
export const baseapisocket = "http://192.168.5.171:9002";
export const baseApiUrlUploadlarge = "http://jaguar.solutio.net.br:3000/upload"
*/

export const token_diploma_digital = "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJFTjI1UTRjTnZEYW1XSjFuUUVIdmhzN1k0VDZEZlhxQnNGZ0N0WVBHS2Y4In0.eyJleHAiOjE2NDkzMzMzODIsImlhdCI6MTY0OTMzMTU4MiwianRpIjoiYjU3YmU4YTktYmI5Ny00ZWY1LWFhMDQtZTI0ODkxNDdmNWM5IiwiaXNzIjoiaHR0cHM6Ly9jbG91ZC5icnkuY29tLmJyL2F1dGgvcmVhbG1zL2Nsb3VkIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6ImY6ZWExZDg2NGYtNzg3MS00M2Q2LWJjYmYtMTE4N2M3ZmI4MTg2OjMzNTU3NzEzNTA0IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoidGVyY2Vpcm9zIiwic2Vzc2lvbl9zdGF0ZSI6ImIxMmQzNWQ0LTg4NDctNDdhNi05NTBiLTMwNjk5OTI2YjdhNSIsImFjciI6IjEiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJ1c3VhcmlvIiwidW1hX2F1dGhvcml6YXRpb24iLCJ1c2VyIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwiY29kZSI6IjMzNTU3NzEzNTA0IiwiY2xvdWRfYXBwX2tleSI6ImFwcC4zMzU1NzcxMzUwNC4xNjQ3OTU2ODI5NDU4IiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiT1NWQUxESU5PICBFVkFOR0VMSVNUQSBERSBNRUxPIiwiY2xvdWRfdG9rZW4iOiJkaFVvYmM2WkdxendtdWQyTkJNbWs3SWJmQ1RwVDVWMWxJVjR1TWFKM2UyZzE4UDdOY2MyeHJUT2lRaWJhdk9zIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiMzM1NTc3MTM1MDQiLCJnaXZlbl9uYW1lIjoiT1NWQUxESU5PIiwiZmFtaWx5X25hbWUiOiIgRVZBTkdFTElTVEEgREUgTUVMTyJ9.Ej3CToRQy4wTR1sG_QHNUIvYlBnuM3sVTNhp0qnjoai9kQIfqjU0x8MfawJZlD6BYXQQ6x9ElNMXvqPove-rSVxQG_sojPCy9-HgXwMihV5n5bBRECyHm0_rHtlDTAfc5Ka0XpObcWlVL9Jx2rwyrJK6p92KC6zBDYfYsdfjpK8ALL2ojmBNpgdroFMaFOrxBAnRpA_HdDUZP-rY3OrJqwHXp38sF30g2yb4zNeAL6Z6-c1Nfe407w7293I0c-BU1YEjSxs3jvOe-_j6TydjFp_CMAHZuw6S72tmDo_JPFMdecFORu2efOnnTyVoDO_aiFZXvrCyNoFLC8ik3CSzNg"
export const token_diploma_digital_refresh = "eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI5NzQ5ZTgyYi1jNGVlLTQ3OGYtODUyMS1mM2RjMDg2NjNiYmIifQ.eyJleHAiOjE2NDkzMzMzODIsImlhdCI6MTY0OTMzMTU4MiwianRpIjoiMDZiNjE1ZGYtNDRiNS00YjllLWJjOWUtYzBjYzY3NmEzM2ZlIiwiaXNzIjoiaHR0cHM6Ly9jbG91ZC5icnkuY29tLmJyL2F1dGgvcmVhbG1zL2Nsb3VkIiwiYXVkIjoiaHR0cHM6Ly9jbG91ZC5icnkuY29tLmJyL2F1dGgvcmVhbG1zL2Nsb3VkIiwic3ViIjoiZjplYTFkODY0Zi03ODcxLTQzZDYtYmNiZi0xMTg3YzdmYjgxODY6MzM1NTc3MTM1MDQiLCJ0eXAiOiJSZWZyZXNoIiwiYXpwIjoidGVyY2Vpcm9zIiwic2Vzc2lvbl9zdGF0ZSI6ImIxMmQzNWQ0LTg4NDctNDdhNi05NTBiLTMwNjk5OTI2YjdhNSIsInNjb3BlIjoicHJvZmlsZSBlbWFpbCJ9.vauAJzWevQnv2GqVvfrtTpOcTio8F3sYbRDTCiGBfWE"
/* 
  Fatec
*/

export const baseApiUrl = store.state.params.viewdocs //"http://jaguar.solutio.net.br:9001";
export const baseApiUrlUpload = store.state.params.upload; // = "http://jaguar.solutio.net.br:9000"
export const baseApiUrlUpload3000 = store.state.params.upload3000;  //"http://jaguar.solutio.net.br:3000";

export const baseapisocket = store.state.params.socket; //"http://jaguar.solutio.net.br:9002";

export const baseApiRel = store.state.params.relatorio;  //`http://jaguar.solutio.net.br/jaguar/wjaprepararel20.jsp?servidor=jaguar.solutio.net.br&banco=bco_nome&relatorio=`;
export const baseApiDocs = store.state.params.docs //`http://jaguar.solutio.net.br/jaguar/`;
export const baseApiRelXLS = store.state.params.relatorioxls; //`http://jaguar.solutio.net.br/jaguar/wjaprepararelxls20.jsp?servidor=jaguar.solutio.net.br&banco=bco_nome&relatorio=`;
export const baseApiRelPDF = store.state.params.relatoriopdf;  //`http://jaguar.solutio.net.br/jaguar/gerarpdfprocesso20.jsp?servidor=jaguar.solutio.net.br&banco=bco_nome&relatorio=`;
export const baseApiUrlUploadlarge = store.state.params.uploadlarge;  //"http://jaguar.solutio.net.br:3000/upload"


// Fim FATEC
 
/* 
Dom Pedro - local

var baseapi = ''

if (document.URL.includes('10.1.1.104') || document.URL.includes('localhost')) {
 baseapi= '10.1.1.104'
} else {
 baseapi = '200.128.108.5' //'191.251.193.210'
}

export const baseApiUrl = `http://${baseapi}:9001`;
export const baseApiUrlUpload = `http://${baseapi}:9000`;
export const baseApiRel = `http://${baseapi}:8888/jaguar/wjaprepararel20.jsp?servidor=jaguar.solutio.net.br&banco=bco_nome&relatorio=`;
export const baseApiDocs = `http://${baseapi}:8888/jaguar/`;
export const baseApiRelXLS = `http://${baseapi}:8888/jaguar/wjaprepararelxls20.jsp?servidor=jaguar.solutio.net.br&banco=bco_nome&relatorio=`;
export const baseApiRelPDF = `http://${baseapi}:8888/jaguar/gerarpdfprocesso20.jsp?servidor=jaguar.solutio.net.br&banco=bco_nome&relatorio=`;
export const baseApiUrlUploadlarge = "http://jaguar.solutio.net.br:3000/upload"
 Fim dompedro
  * /
/*
  Fazag

export const baseApiUrl = "http://192.168.3.2:9001";
export const baseApiUrlUpload = "http://192.168.3.2:9000";
export const baseApiRel = `http://192.168.3.2:8080/jaguar/wjaprepararel20.jsp?servidor=jaguar.solutio.net.br&banco=bco_nome&relatorio=`;
export const baseApiDocs = `http://192.168.3.2/jaguar/`;
export const baseApiRelXLS = `http://192.168.3.2:8080/jaguar/wjaprepararelxls20.jsp?servidor=jaguar.solutio.net.br&banco=bco_nome&relatorio=`;
export const baseApiUrlUploadlarge = "http://192.168.3.2:3000/upload"
*/
/*
  FAN

export const baseApiUrl = "http://academico.fan-edu.com.br:9001";
export const baseApiUrlUpload = "http://academico.fan-edu.com.br:9000";
export const baseApiRel = `http://academico.fan-edu.com.br:8081/jaguar/wjaprepararel20.jsp?servidor=localhost&banco=bco_nome&relatorio=`;
export const baseApiRelPDF = `http://academico.fan-edu.com.br:8081/jaguar/wjaprepararel20.jsp?servidor=localhost&banco=bco_nome&relatorio=`;
export const baseApiDocs = `http://academico.fan-edu.com.br/jaguar/`;
export const baseApiRelXLS = `http://academico.fan-edu.com.br:8081/jaguar/wjaprepararelxls20.jsp?servidor=localhostr&banco=bco_nome&relatorio=`;
export const baseApiUrlUploadlarge = "http://academico.fan-edu.com.br:3000/upload"
export const baseapisocket = "http://academico.fan-edu.com.br:9002";
*/
// fim FAN

export const http = axios.create({
  baseURL: `${baseApiUrl}/fxjaguar`,
});

export const httpEmail = axios.create({
  baseURL: `${baseApiUrl}/fxjaguar/email`,
});

export const httpRel = axios.create({
  baseURL: `http://jaguar.solutio.net.br/jaguar/wjaprepararel.jsp?servidor=jaguar.solutio.net.br&banco=bco_nome&relatorio=`,
});

export function transform_decimal(value, precision) {
  var pow = Math.pow(10, precision);
  return (
    (Math.ceil(pow * value) + Math.ceil(pow * value - Math.ceil(pow * value))) /
    pow
  );
}

function randomIndexFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function encryption(input) {
  var inputString = input;
  var outputString = "";

  var asciiArr  = [];
  var atozArr  = [];
  var encryptedString  = [];
  var i = 0;
  var code = 0;

  if (inputString.length !== 0) {
    for (i = 0; i < inputString.length; i++) {
      asciiArr[i] = inputString[i].charCodeAt(0);
    }
    for (i = 0, code = 65; i < 26; i++, code++) {
      atozArr[i] = String.fromCharCode(code);
    }
    var position = randomIndexFromInterval(0, atozArr.length - 1);
    var positionAscii = atozArr[position].charCodeAt(0);
    for (i = 0; i < inputString.length; i++) {
      encryptedString[i] =
        parseInt(asciiArr[i]) + parseInt(atozArr[position].charCodeAt(0));
    }
    encryptedString[asciiArr.length] = positionAscii;
    for (i = 0; i < encryptedString.length; i++) {
      outputString = outputString + String.fromCharCode(encryptedString[i]);
    }
  } else {
    //
  }
  return outputString;
}

export function decryption(input) {
  var inputBox = input;
  var retorno = "";
  if (inputBox !== 0) {
    var encryptedString = input;
    var key = encryptedString[encryptedString.length - 1];
    var decryptedString = [];
    var i = 0;
    for (i = 0; i < encryptedString.length - 1; i++) {
      decryptedString[i] = encryptedString[i].charCodeAt(0) - key.charCodeAt(0);
    }
    retorno = "";
    for (i = 0; i < decryptedString.length; i++) {
      retorno = retorno + String.fromCharCode(decryptedString[i]);
    }
  } else {
    //
  }

  return retorno;
}

export function formatData(d) {
  var retorno = new Date(d).toLocaleDateString("pt-BR", { timeZone: "UTC" });
  if  (retorno === 'Invalid Date') {
    retorno = d
  }
  return retorno
}

export function hoje() {
  return new Date().toLocaleDateString("pt-BR", {
    timeZone: "UTC",
  });
}

export function formatMoeda(v, decimais) {
  decimais = decimais !== undefined ? decimais : 2;
  return parseFloat(v)
    .toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: decimais,
      maximumFractionDigits: decimais,
    })
    .replace("R$", "")
    .trim();
}


export function valida_cpf(cpf){
      var numeros, digitos, soma, i, resultado, digitos_iguais;
      digitos_iguais = 1;
      if (cpf.length < 11)
            return false;
      for (i = 0; i < cpf.length - 1; i++)
            if (cpf.charAt(i) != cpf.charAt(i + 1))
                  {
                  digitos_iguais = 0;
                  break;
                  }
      if (!digitos_iguais)
            {
            numeros = cpf.substring(0,9);
            digitos = cpf.substring(9);
            soma = 0;
            for (i = 10; i > 1; i--)
                  soma += numeros.charAt(10 - i) * i;
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0))
                  return false;
            numeros = cpf.substring(0,10);
            soma = 0;
            for (i = 11; i > 1; i--)
                  soma += numeros.charAt(11 - i) * i;
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1))
                  return false;
            return true;
            }
      else
            return false;
}


export function chk_data(d) {
  var arrData = d.split("/");
  var errodata = false;

  if (parseInt(arrData[0]) < 0 || parseInt(arrData[0]) > 31) {
    errodata = true;
  }

  if (parseInt(arrData[1]) < 1 || parseInt(arrData[1]) > 12) {
    errodata = true;
  }

  if (
    new Date().getFullYear() - parseInt(arrData[2]) > 100 ||
    new Date().getFullYear() - parseInt(arrData[2]) < 0
  ) {
    errodata = true;
  }

  if (
    (parseInt(arrData[1]) === 4 ||
      parseInt(arrData[1]) === 6 ||
      parseInt(arrData[1]) === 9 ||
      parseInt(arrData[1]) === 11) &&
    parseInt(arrData[0]) > 30
  ) {
    errodata = true;
  } else {
    if (parseInt(arrData[1]) === 2) {
      if (parseInt(arrData[0]) > 28 && parseInt(arrData[2]) % 4 !== 0) {
        errodata = true;
      }

      if (parseInt(arrData[0]) > 29 && parseInt(arrData[2]) % 4 === 0) {
        errodata = true;
      }
    }
  }

  return errodata;
}

export function novoregistro(registro) {
  registro.funcao = "Inc";
  
  Object.keys(registro).reduce((novoObj: any, key) => {
    if (
      key !== "funcao" &&
      key !== "proc" &&
      key !== "codigoSistema" &&
      key !== "usr"
    ) {
      registro[key] = "";
    }
  }, {});

  return registro;
}

export async function editarregistro(registro, item) {
  registro.funcao = "Upd";
  await Object.keys(item).reduce((novoObj: any, key) => {
    if (
      key !== "funcao" &&
      key !== "proc" &&
      key !== "codigoSistema" &&
      key !== "usr"
    ) {
          //s    console.log(key)
          //    console.log(key +' = '+registro[key])

      if (registro[key] !== undefined) {
        try {
        registro[key] = item[key].trim();

        } catch (e) {
        registro[key] = item[key];
          
        }
      }
    }
  }, {});


  return registro;
}

export async function salvarregistro(registro: any, callback: any) {
  if (registro.funcao === "") {
    registro.funcao = "Inc";
  }
  await conn.send("", registro).then((res) => {
    callback((res.dados as any).data.erro);
    return (res.dados as any).data;
  });
}

export function excluirregistro(registro, id, registros, callback) {
  conn.send("", registro).then((res) => {
    callback((res.dados as any).data.erro);
  });
  return registros.filter((r : any) => r[id] !== registro[id]);
}

export async function listacentro_custos(e_id) {    
  return await conn.send("", {
    proc: "[FX jaguar cadastros centro_custos]",
    0: "list",
    1: null,
    2: (store.state.contexto as any).BD[0].usr_id,
    3: null,
    4: "*",
    5: null,
    6: null,
    7: null,
    8: e_id
  });
}

export async function listabancos(e_id) {
  if (e_id === undefined) {
    e_id = null
  }
  return await conn.send("", {
    proc: "[FX jaguar cadastros fin_banco]",
    0: "list",
    1: null,
    2: (store.state.contexto as any).BD[0].usr_id,
    3: null,
    4: e_id,
    5: '*',
  });
}

export async function listacurriculosdocurso(s_id) {
  return await conn.send("", {
    proc: "[fx jaguar listas]",
    1: "listacurriculosdocurso",
    2: s_id,
  });
}

export async function listcurriculosunidade(e_id) {
  return await conn.send("", {
    proc: "[fx jaguar listas]",
    1: "listcurriculosunidade",
    2: e_id,
  });  
}

export async function listunidademedida() {
  return await conn.send("", {
    proc: "[FX jaguar cadastros tab_unidades]",
  });  
}

export async function listaDisciplinasDoCursoECurriulo(s_id, g_id, dspa) {
  return await conn.send("", {
    proc: "[fx jaguar listas]",
    1: "listadisciplinasdocursoecurriculo",
    2: s_id,
    3: g_id,
    4: dspa,
  });
}

export async function listaEventosContabeis() {
  return await conn.send("", {
    proc: "[FX jaguar cadastros eventos_contabeis]",
    1: "listec",
    2: "0",
    3: "0",
    4: "",
    5: "*",
  });
}

export async function listaNivelEnsino(e_id) {
  return await conn.send("", {
     proc: "[FX jaguar cadastros escolas_nivel_ensino]",
        funcao: "list",
        codigoSistema: "0",
        usr: (store.state.contexto as any).BD[0].usr_id,
        ene_id: "",
        e_id: e_id,
        ene_descricao: "",
  });

}

export async function listaCurriculoCurso(s_id) {
  return await conn.send("", {
    proc: "[fx jaguar listas]",
    1: "listacurriculocurso",
    2: s_id,
  });
}

export async function listaCurriculos() {
  return await conn.send("", {proc:"[FX jaguar cadastros Curriculos]",1:"list",2:"0",3:"0",4:"*"});
}

export async function enviarEmail(  destinos : any,  assunto : any,  mensagem : any) {
  //console.log('config',(store.state.contexto as any).BD)
  console.log('filial',(store.state.contexto as any))
  var config = {    banco: (store.state.contexto as any).BD[0].bco_nome,
                    destinos : destinos,
                    assunto: assunto,
                    mensagem: mensagem,
                    empresa: (store.state.contexto as any).FILIAL[0].e_nomefantasia,
                    smtp_host: (store.state.contexto as any).FILIAL[0].smtp_host,
                    smtp_porta: (store.state.contexto as any).FILIAL[0].smtp_porta,
                    user_name: (store.state.contexto as any).FILIAL[0].user_name,
                    user_password: (store.state.contexto as any).FILIAL[0].user_password }
                                  
    return await conn.send("email", config)
      
  }


export async function listaFiliais() {
//  console.log(store.state.contexto.perfilgeral)
  return await conn.send("", {
    proc: "[FX jaguar cadastros Escolas]",
    0: "list",
    1: (store.state.contexto as any).BD[0].usr_id,
    2: "0",
    3: "0",
    4: "*",
    5: store.state.contexto.perfilgeral,
    6: store.state.contexto.aluno ? store.state.contexto.aluno.a_id : null
  });
}

export async function listaTurnos(e_id) {
  return await conn.send("", {
    proc: '[FX jaguar cadastros turnos]',
    1: "listturnos",
    2: (store.state.contexto as any).BD[0].usr_id,
    3: '0',
    4: "0",
    5: "*",
    6: null,
    7: null,
    8: null,
    9: null,
    10: null,
    11: null,
    12: e_id,
  });
}


export async function listaPA(s_id,g_id) {
  return await conn
    .send("", {
      proc: "[FX jaguar cadastros disciplinas_serie_pa]",
      1: "list",
      2: (store.state.contexto as any).BD[0].usr_id,
      3: '0',
      4: '',
      5: g_id,
      6: s_id,
      7: '',
      8: "*",
    });
   
}

export async function listaCursos(e_id) {

  e_id = e_id ?? (store.state.contexto as any).FILIAL[0].e_id;
  console.log('store.state.contexto as any).BD[0].usr_id', (store.state.contexto as any).BD[0].usr_id);
  console.log('e_id', e_id);
  return await conn.send("", {
    proc: "[FX jaguar cadastros Cursos ]",
    0: "list",
    1: (store.state.contexto as any).BD[0].usr_id,
    2: e_id,
    3: "0",
    4: "*",
  });
}

export async function listaDepartamentos(e_id) {
  return await conn.send("", {
    proc: "[FX jaguar cadastros departamento]",
    0: "list",
    1: (store.state.contexto as any).BD[0].usr_id,
    2: null,
    3: null,
    4: '*',
    5: null,
    6: null,
    7: null,
    8: null,
    9: e_id
  });
}

export async function listaUndMedidas() {
  return await conn.send("", {
    proc: "[fx jaguar listas]",
    1: "listaundmedita",
  });
}

export async function listarubrica(e_id) {
  return await conn.send("", { proc: "[fx jaguar listas]", 1: "rubricas" , 2: e_id});
}

export async function listaprocessos(e_id) {
  return await conn.send("", { proc: "[fx jaguar listas]", 1: "periodos", 2: e_id });
}

export async function listaprocessosregdocentes(p_id) {
  if (p_id === undefined) {
    p_id = '0'
  }
  return await conn.send("", { proc: "[fx jaguar listas]", 1: "periodosregdocentes" , 2:p_id, 3: (store.state.contexto.FILIAL[0] as any).e_id });
}

export async function listacalendarioacademico(e_id, proc_id) {
  return await conn.send("", {
    proc: "[fx jaguar listas]",
    1: "calendarioacademico",
    2: e_id,
    3: proc_id,
  });
}

export async function listaTabDesc(e_id) {
  return await conn.send("", {
    proc: "[FX jaguar cadastros tipos_desconto]",
    1: "list",
    2: (store.state.contexto as any).BD[0].usr_id,
    3: "0",
    4: "0",
    5: e_id,
    6: "%"
  });
}

export async function listaAssinantes(e_id) {
  return await conn.send("", {proc: `[FX jaguar cadastros escolas_assinaturas] 'list',null,${(store.state.contexto as any).BD[0].usr_id},null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,${e_id}`});
}

export async function listaSalas(e_id) {
  return await conn.send("", {
    proc: "[FX jaguar Basico listaSalas]",
    1: e_id,
  });
}

export async function listafc() {
  return await conn.send("", { proc: "[fx jaguar listas]", 1: "contacaixa" });
}

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLocaleLowerCase();
}


export async function listaUF() {
  return await conn.send("", { banco: "manager_jaguar", proc: "[cep] 'estados'" })
}

export async function listamunicipios(uf) {
      return await conn.send("", { banco: "manager_jaguar", proc: `[cep] 'cidades','${uf}'` })
}

export async function findCEP(cep: any) {
  cep = cep.split('.').join('').split('-').join('')
  return await conn.send("", { banco: "manager_jaguar", proc: `[cep] 'cep', null , '${cep}'` })
}

export async function uploadfile(arquivo : any,condicao : boolean) {
  if (condicao) {
      var url = `${baseApiUrlUploadlarge}?destino=${arquivo.pasta}`;
      const formData = new FormData();
      formData.append("input-file", arquivo.file);
      var progress = 0;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          console.log("upload - resultado", res);
          //subindo = false;
        });

  } else {
          console.log("não subindo");

  }
}

export function trata_campo_moeda(valor: any) {
      if (valor.toString().includes(".") && valor.toString().includes(",")) {
        return valor.toString().split(".").join("").split(",").join(".");
      } else {
        if (valor.toString().includes(",")) {
          return valor.toString().split(",").join(".");
        } else {
          return valor.toString();
        }
      }
}
    
export function abrePDF(item: any) {
  const action = baseApiUrlUpload; //Config.baseApiUrl + "/sendMePDF";
  const destino = `/${(store.state.contexto as any).BD[0].bco_pasta.trim()}/${item.pasta}`
  const anexo = action + destino;

  const anchor = window.document.createElement("a");
  anchor.href = anexo; //window.URL.createObjectURL(blob);
  anchor.target = "blank";
  anchor.download = item.nome;
  anchor.click();
  window.URL.revokeObjectURL(anchor.href);
}

export function Left(str: any, n){
	if (n <= 0)
	    return "";
	else if (n > String(str).length)
	    return str;
	else
	    return String(str).substring(0,n);
}

export function Right(str: any, n){
    if (n <= 0)
       return "";
    else if (n > String(str).length)
       return str;
    else {
       var iLen = String(str).length;
       return String(str).substring(iLen, iLen - n);
    }
}

export const ativoinativo = [{ label: 'Ativo', value: 'Ativo' }, { label: 'Inativo', value: 'Inativo' }]
export const simnao = [{label: 'Sim', value:'Sim'},{label:'Não', value:'Não'}]
export const sexo = [{label: 'Masculino', value:'M'},{label:'Feminino', value:'F'}]
export const tipodocid = [];
export const meses = [
  {label: 'Janeiro', value: 1},
  {label: 'Fevereiro', value: 2},
  {label: 'Março', value: 3},
  {label: 'Abril', value: 4},
  {label: 'Maio', value: 5},
  {label: 'Junho', value: 6},
  {label: 'Julho', value: 7},
  {label: 'Agosto', value: 8},
  {label: 'Setembro', value: 9},
  {label: 'Outubro', value: 10},
  {label: 'Novembro', value: 11},
  {label: 'Dezembro', value: 12},
]
tipodocid['Brasileiro(a)'] = [
  { label: 'Carteira de identidade emitida por órgãos de identificação' },
  { label: 'Carteira de Trabalho e Previdência Social - CTPS' },
  { label: 'Carteira Nacional de Habilitação (CNH)' },
  { label: 'Carteira de identidade profissional expedida por órgãos fiscalizadores de exercício de profissão regulamentada (OAB, CRC, CRM, CRA, CREA etc)' },
  { label: 'Carteiras funcionais emitidas por órgãos públicos' },
  { label: 'Documento de identificação militar' },
  { label: 'Passaporte' }];

tipodocid['Estrangeiro(a)'] = [
    {label:'Passaporte'},
    {label:'Carteira do Registro Nacional Migratório (CRNM)'},
    {label:'Cédula de Identidade de Estrangeiro (CIE/RNE)'},
    {label:'Documentos de identificação dos Estados do Mercosul e associados, admitidos em acordo internacional'},
  { label: 'Protocolo de refúgio, previsto no art. 21 da Lei 9.474, de 22 de julho de 1997' }];
  
export const regFuncionario = {
  proc: "[FX jaguar cadastros funcionarios]",
  funcao: "",
  usr: null,
  codigoSistema: null,
  func_matricula: "",
  func_nome: "",
  func_nome_cracha: "",
  dp_id: "",
  func_estcivil: "",
  func_sexo: "",
  func_datanasc: "",
  func_funcao: "",
  func_admissao: "",
  func_cep: "",
  func_endereco: "",
  func_bairro: "",
  func_cidade: "",
  func_uf: "",
  func_telefone: "",
  func_celular: "",
  func_email: "",
  func_pai: "",
  func_mae: "",
  func_naturalidade: "",
  func_nacionalidade: "",
  grau_instrucao: "",
  func_ctps: "",
  func_rg: "",
  func_ufrg: "",
  func_cpf: "",
  func_pis: "",
  func_titulo: "",
  func_zona: "",
  func_secao: "",
  func_reservista: "",
  func_habilitacao: "",
  func_salario: "",
  func_banco: "",
  func_agencia: "",
  func_conta: "",
  func_situacao: "",
  func_regime: "",
  func_obs: "",
  func_id: "",
  prof_id: "",
  loginsistema: "",
  loginprofessor: "",
  func_btu_id: "",
  fs_id: "",
  func_ch_coord: "",
  func_ch_docencia: "",
  func_funcaodpto: "",
  e_id: "",
  f_id: "",
  j_id: "",
};

 export function addZeroes(num, len) {
    var numberWithZeroes = String(num);
    var counter = numberWithZeroes.length;
      
    while(counter < len) {  
          numberWithZeroes = "0" + numberWithZeroes;    
          counter++;
    }
  
  return numberWithZeroes;
}

export default {
  Left,
  Right,
  abrePDF,
  uploadfile,
  listacurriculosdocurso,
  baseApiDocs,
  baseApiUrl,
  baseApiUrlUpload,
  baseApiRel,
  baseApiRelXLS,
  baseApiUrlUploadlarge,
  userKey,
  http,
  capitalize,
  hoje,
  listaTurnos,
  listaPA,
  listaNivelEnsino,
  listaCurriculos,
  listcurriculosunidade,
  listaEventosContabeis,
  listaSalas,
  listaDisciplinasDoCursoECurriulo,
  listacalendarioacademico,
  listunidademedida,
  listaprocessos,
  listarubrica,
  listafc,
  listaDepartamentos,
  listabancos,
  listaFiliais,
  listaTabDesc,
  listaCursos,
  listaUndMedidas,
  novoregistro,
  editarregistro,
  salvarregistro,
  excluirregistro,
  chk_data,
  formatData,
  formatMoeda,
  transform_decimal,
  decryption,
  encryption,
  enviarEmail,
  ativoinativo,
  simnao,
  listaUF,
  listamunicipios,
  findCEP,
  sexo,
  listaAssinantes,
  trata_campo_moeda,
  meses,
  addZeroes,
  regFuncionario
};
